import axios from 'axios';

class UtilApi {
    constructor() {
        this.endpoint = process.env.VUE_APP_BASE_API;
    }

    geo(address) {
        return axios.get(`${this.endpoint}/util/geo`, {params:{address:address}}).then(response => response.data);
    }

    holidays(year, month) {
        return axios.get(`${this.endpoint}/util/holidays/${year}/${month}`, {}).then(response => response.data);
    }
}

export default new UtilApi();
